import quoteService from '@/services/quoteService';
import helperService from '@/services/helperService';

const Animate = () => import(/* webpackChunkName: "view-Shipping" */ '@/common/animate/Animate.vue');

export default {
	name: 'thanks',
	data: function() {
		return {
			name: '',
		};
	},
	components: {
		'animate-scene': Animate,
	},
	created: function() {
		this.checkForQuoteData();
	},
	mounted: function() {
		helperService.updateProgress(8);
		setTimeout(() => {
			quoteService.clearStorage();
		}, 1000);
	},
	beforeRouteLeave: function(to, from, next) {
		next(false);
	},
	methods: {
		checkForQuoteData: function() {
			const name = JSON.stringify(quoteService.getName());
			let name_ref = '';

			if (name) {
				name_ref = JSON.parse(name);
			}

			this.name = name_ref;
		},
	},
};
